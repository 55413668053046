
import "./App.css";
import { useState } from "react";

function App() {

  const[playSound, set_playsound] =  useState(false);



  const agoTag = <span className="ago" 
  
  // style={{display:"none"}}
  
  ></span>;
  return (
    <>
      <main class="flex min-h-screen flex-col items-center justify-between  bg-white text-black">


   
      

      



        <nav
          class="bg-white dark:bg-transparent fixed w-full z-20 top-0 left-0 "
          style={{ backgroundColor: "rgba(255,217,224, 0.455)" }}
   
        >
          <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a class="flex items-center" href="#">
              <div class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white flex">
                Ice &amp; Preaw
                <div class="relative">
                  {" "}
                  <img
                    src= {  playSound   ? "/icons/mute.svg"  :   "/icons/play.svg"}
                    class="ml-3"
                    alt="song"
                    style={{ height: "24px" }}
                    onClick={() => {


                    set_playsound(!playSound)
                    
                    }}
                  />
                </div>
              </div>
            </a>
            
            
          


         
        
          </div>
        </nav>
        <div>
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/WtcKJtwMD2E?si=HerRwUX3eIb81Jqn&amp;start=55&amp;autoplay=1" title="YouTube video player" allow="autoplay; encrypted-media" allowfullscreen=""></iframe> */}

        <div className="hidden">

          {playSound  &&

        <iframe width="560" height="315" src="https://www.youtube.com/embed/3aCctY3DGac?si=zcqEpw6jEJIKefyB;start=26&amp;autoplay=1" title="YouTube video player" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>
      }
        </div>
       




 </div>


        <div class=" h-screen relative bg-img" id="#">
          <div
            class="absolute bottom-10 text-title aos-init aos-animate"
            data-aos="fade-down"
          >

            
            <h1
              class="text-white font-semibold text-3xl"
             //  style={{ color: "#7E9680" }}

              // style={{ color: "#2C6975" }}

             // style={{ color: "#6096FD" }}
              style={{ 
                color: "#9f705e" }}
            >
             
              Happy 1st wedding anniversary
            </h1>
           {/*  <div
              class="mt-3 text-white text-center  font-semibold text-xl"
              style={{ color: "#9f705e" }}
            >
            รักมากกว่าเดิม เพิ่มเติมตังให้เมียหมดเเล้ว
            </div> */}

          </div>
        </div>
        <section class="p-8 text-sky-900">
          <div class="py-4 text-center text-3xl ">
         
            
      


            <div>วันอาทิตย์ที่ 12 พฤศจิกายน 2566</div>
            <div class="mt-1 flex justify-center" >
            {/* <a  target="_blank" href="https://calendar.google.com/calendar/event?action=TEMPLATE&amp;tmeid=MjJqZTgxcTQ1bWprbjB2ZWpzdmlncmJpajEgZmFtaWx5MTIwMDQ5OTQ2MjQwNTM2NTIxNDlAZw&amp;tmsrc=family12004994624053652149%40group.calendar.google.com"><img border="0" src="https://www.google.com/calendar/images/ext/gc_button1_en-GB.gif"/></a>
            */}
            </div>

            
          </div>
{/*           <p class="py-4 text-center text-xl">
            ขอเรียนเชิญแขกผู้มีเกียรติ ญาติผู้ใหญ่ พี่ๆน้องๆ เพื่อนๆ มิตรสหาย
            ที่เคารพรักทุกท่าน
              ร่วมเป็นเกียรติและแสดงความยินดีในงานพิธีมงคลสมรสของ

              
          </p> */}

          <p class="py-4 text-center text-2xl">
            สุขสันต์ครบรอบวันแต่งงาน
            <br></br> <br></br>
            Love is not about how many days, months, or years you have been together. Love is about how much you love each other every single day.


          </p>
          <p
            class="py-4 text-center text-2xl mt-24 aos-init"
            data-aos="fade-down"
          >
            นายชัยชนะ สาทอง (ไอซ์)
          </p>
          <img
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            src="/icons/double-heart.png"
            class="m-auto my-8 aos-init"
            alt=""
            style={{ height: "8rem" }}
          />
          <p class="py-4 text-center text-2xl aos-init" data-aos="fade-up">
            นางสาว ไพลิน วงศ์คำ (เปรียว)
          </p>
        </section>

        <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8 my-20">
          <div className="w-100 container" id="timeContainer">
            <div className="days">
              <div className="numbers" id="days">
                            </div>
              days   {agoTag}
            </div>
            <div className="days">
              <div className="numbers" id="hours">
              
              </div>
              hours    {agoTag}
            </div>
            <div className="minutes">
              <div className="numbers" id="mins">
               
              </div>
              minutes    {agoTag}
            </div>
            <div className="seconds">
              <div className="numbers" id="secs">
               
              </div>
              seconds    {agoTag}
            </div>
          </div>





          <div id="gallery">
  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 my-16 mx-auto max-w-100">
    <div className="grid gap-4">
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-right"
      >
        <img
      
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/1.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-right"
      >
        <img
     
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/5.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   

    </div>

    <div className="grid gap-4">
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-top"
      >
        <img
         
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/2.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-top"
      >
        <img
         
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/6.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
    </div>

    <div className="grid gap-4">
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-down"
      >
        <img
         
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/3.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-down"
      >
        <img
       
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/7.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
    </div>

    <div className="grid gap-4">
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-left"
      >
        <img
         
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/4.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
   
      <div
        className="image-container aos-init aos-animate"
        data-aos="fade-left"
      >
        <img
      
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          className="h-auto max-w-full rounded-lg image"
          sizes="100vw"
          src="/images/8.jpg"
          style={{
           // position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            color: "transparent"
          }}
        />
      </div>
    </div>




  </div>
</div>









       

          
        {/*   <h1
            className="text-xl text-fuchsia-950 text-center mb-16 sans"
            id="comment"
          >
            อวยพร
          </h1>
          <div className="flex items-center w-100">
            <div className="m-auto">
              <div
                className="fb-comments w-100 fb_iframe_widget fb_iframe_widget_fluid_desktop"
                data-href="https://wedding-web-lime.vercel.app/"
                data-width={425}
                data-numposts={5}
                fb-xfbml-state="rendered"
                fb-iframe-plugin-query="app_id=342214374546756&container_width=0&height=100&href=https%3A%2F%2Fwedding-web-lime.vercel.app%2F&locale=th_TH&numposts=5&sdk=joey&version=v18.0&width=425"
              >
                <span
                  style={{
                    verticalAlign: "bottom",
                    width: "425px",
                    height: "816px",
                  }}
                >
                  <iframe
                    name="f20aab8985f73ec"
                    width="425px"
                    height="100px"
                    data-testid="fb:comments Facebook Social Plugin"
                    title="fb:comments Facebook Social Plugin"
                    frameBorder={0}
                    allowTransparency="true"
                    allowFullScreen="true"
                    scrolling="no"
                    allow="encrypted-media"
                    src="https://www.facebook.com/v18.0/plugins/comments.php?app_id=342214374546756&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df14a73b2723a574%26domain%3Dwww.teekawat-plaifon.online%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.teekawat-plaifon.online%252Ff38222c0f62cfa4%26relation%3Dparent.parent&container_width=0&height=100&href=https%3A%2F%2Fwedding-web-lime.vercel.app%2F&locale=th_TH&numposts=5&sdk=joey&version=v18.0&width=425"
                    style={{
                      border: "none",
                      visibility: "visible",
                      width: "425px",
                      height: "816px",
                    }}
                    className
                  />
                </span>
              </div>
            </div>
        
        
          </div> */}
          


          
        </div>
      </main>
    </>
  );
}

export default App;
